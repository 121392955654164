import React from 'react';
import './App.css';
import LoanCalculator from './LoanCalculator';

function App() {
  return (
    <div className="App flex-1 justify-center flex ">
      <LoanCalculator />
      <div className='background'>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
        <span className='animationCircle'></span>
      </div>
    </div>
  );
}

export default App;
