import React, { useState } from 'react';

function formatToLocaleString(value) {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;
  return numberValue.toLocaleString('nb-NO');
}


function parseLocaleString(string) {
  return parseFloat(string.replace(/\s+/g, '').replace(',', '.'));
}


function InputSlider({ className, min, max, value, setValue, step, title, unit }) {
  return (
    <>
      <label className="block text-start">{title}</label>
      <input
        className={className}
        type="range"
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={(e) => setValue(parseFloat(e.target.value))}
      />
      <div className="relative flex items-center border rounded-full px-3 py-2 w-full mt-3 mb-8">
        <input
          className={`flex-grow bg-transparent border-0 outline-none ${unit && "pr-10"}`}
          type="text"
          value={formatToLocaleString(value)}
          onChange={(e) => {
            const parsedValue = parseLocaleString(e.target.value);
            setValue(isNaN(parsedValue) ? 0 : parsedValue);
          }}
        />
        {unit && <span className="absolute right-3">{unit}</span>}
      </div>
    </>
  );
}

function LoanCalculator() {
  const [loanAmount, setLoanAmount] = useState(350000);
  const [years, setYears] = useState(8);
  const [equity, setEquity] = useState(0);
  const [interestRate, setInterestRate] = useState(4.5);
  const [extraPayment, setExtraPayment] = useState(0);

  const calculateMonthlyPayment = () => {
    const principal = loanAmount - equity;
    const rate = interestRate / 100 / 12;
    const months = years * 12;

    const monthlyPaymentWithoutExtra =
      (principal * rate) /
      (1 - Math.pow(1 + rate, -months));

    let totalMonthsWithExtra = 0;
    let remainingWithExtraPayment = principal;
    while (remainingWithExtraPayment > 0) {
      const interestWithExtra = remainingWithExtraPayment * rate;
      const principalPaidWithExtra = (monthlyPaymentWithoutExtra + extraPayment) - interestWithExtra;

      if (remainingWithExtraPayment - principalPaidWithExtra < 0) {
        totalMonthsWithExtra++;
        break;
      }

      remainingWithExtraPayment -= principalPaidWithExtra;
      totalMonthsWithExtra++;
    }

    const totalMonthsSaved = months - totalMonthsWithExtra;
    const totalYearsSaved = Math.floor(totalMonthsSaved / 12);
    const remainingMonths = totalMonthsSaved % 12;

    if (principal <= 0) {
      return {
        monthlyPayment: 0,
        fasterYears: 0,
        fasterMonths: 0
      };
    }

    return {
      monthlyPayment: monthlyPaymentWithoutExtra,
      fasterYears: totalYearsSaved,
      fasterMonths: remainingMonths
    };
  };






  const { monthlyPayment, fasterYears, fasterMonths } = calculateMonthlyPayment();

  return (
    <div className="m-2 py-10 md:my-10 sm:px-10 bg-slate-900 bg-opacity-90 rounded-2xl">
      <h1 className="w-full text-2xl font-thin mb-10 uppercase">Lånekalkulator Bil</h1>

      <InputSlider className="slider" title="Pris" unit="kr" min="50000" max="1500000" value={loanAmount} setValue={setLoanAmount} />
      <InputSlider className="slider" title="Nedbetalingstid" unit="år" min="1" max="14" value={years} setValue={setYears} />
      <InputSlider className="slider" title="Egenkapital" unit="kr" min="0" max="500000" value={equity} setValue={setEquity} />
      <InputSlider className="slider" title="Rente" unit="%" min="0" max="10" step="0.1" value={interestRate} setValue={setInterestRate} />
      {/* <InputSlider className="slider" title="Ekstra betaling per måned" unit="kr" min="0" max="100000" value={extraPayment} setValue={setExtraPayment} /> */}

      <div className='font-bold text-xl'>Månedlig betaling: {formatToLocaleString(monthlyPayment.toFixed(0))} ({years} år)</div>
      {extraPayment > 0 && <div>Betales ned {fasterYears} år og {fasterMonths} mnd raskere</div>}
    </div>
  );
}

export default LoanCalculator;
